



import Component from "vue-class-component";
import Vue from "vue";
import { Watch } from "vue-property-decorator";
import DealerLanding from "@/components/DealerLanding.vue";

@Component({
    components: { DealerLanding },
})
export default class LandingPage extends Vue {
    public name = "LandingPage";
    public dealerId: number | null = null;
    public categoryId: number | null = null;
    public categories: string | null = null;
    public $refs!: {
        landing: DealerLanding;
    };

    public async created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("dealerId")) {
            this.dealerId = Number(urlParams.get("dealerId"));
        }
        if (urlParams.has("categoryId")) {
            this.categoryId = Number(urlParams.get("categoryId"));
        }
    }

    @Watch("dealerId")
    public async onDealerChange() {
        if (this.dealerId) {
            this.$refs.landing.dealerId = this.dealerId;
        }
    }

    @Watch("categoryId")
    public async onCategoryChange() {
        if (this.categoryId) {
            this.$refs.landing.categoryId = this.categoryId;
        }
    }

    @Watch("categories")
    public async onCategoriesChange() {
        if (this.categories) {
            this.$refs.landing.categories = this.categories;
        }
    }
}
